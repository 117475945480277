<template>
  <v-container class="ma-0 pa-0 text-center">
   <hero-alt
     v-bind="episode"
     :src="getImageSrc(episode)"
     :gradientTop="getGradientTop(episode)"
     :gradientBottom="getGradientBottom(episode)"
   />
    <v-row>
       <v-col cols="12">
         <div class="mt-3 ml-10 ml-md-n8 mb-md-6">
         <player :audioLink="episode.audio_link"></player>
         </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3" class="pb-0 text-left">
        <template v-if="episode.prev"><a :href="'/podcast/' + episode.prev" class="ml-3 mr-1">&lt; Previous</a>|</template>
        <template v-if="episode.series_slug"><a :href="'/' + episode.series_slug" class="ml-1 mr-1">All Episodes</a></template>
        <template v-if="episode.next">|<a :href="'/podcast/' + episode.next" class="ml-1">Next &gt;</a></template>
      </v-col>
      <v-col cols="12" md="9" class="pb-3 text-left text-md-right">
        <div class="ml-3">
        Listen on:
        <template v-if="episode.apple_link"><a target="_new" :href="episode.apple_link">Apple Podcasts</a> | </template>
        <template v-if="episode.google_link"><a target="_new" :href="episode.google_link">Google Podcasts</a> | </template>
        <template v-if="episode.iheart_link"><a target="_new" :href="episode.iheart_link">iHeartRadio</a> | </template>
        <template v-if="episode.pandora_link"><a target="_new" :href="episode.pandora_link">Pandora</a> | </template>
        <template v-if="episode.spotify_link"><a target="_new" :href="episode.spotify_link">Spotify</a> | </template>
        <template v-if="episode.amazon_link"><a target="_new" :href="episode.amazon_link">Amazon</a> | </template>
        <a :href="episode.feed_url">RSS</a>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0" v-if="episode.transcript">
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
        >
          <v-tab key="showNotes" >Show Notes</v-tab>
          <v-tab key="transcript" >Transcript</v-tab>
        </v-tabs>
        <v-tabs-items v-if="episode.transcript" v-model="tab" class="transparent">
          <v-tab-item key="showNotes">
            <div v-html="episode.description" class="episode-desc mt-4"/>
            <div v-if="episode.special_content" v-html="episode.special_content" class="episode-desc"/>
            <image-grid :episodeIdentifier="episode.episode_identifier" :seriesSlug="episode.series_slug"/>
          </v-tab-item>
          <v-tab-item key="transcript">
            <div v-html="episode.transcript" class="episode-desc mt-4"/>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col class="pt-0" v-else>
        <div v-html="episode.description" class="episode-desc"/>
        <div v-if="episode.special_content" v-html="episode.special_content" class="episode-desc"/>
        <image-grid :episodeIdentifier="episode.episode_identifier" :seriesSlug="episode.series_slug"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
         <a :href="'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Flavaforgood.com%2Fpodcast%2F' + episode.slug" target="_blank">
           <v-icon large class="ma-2">mdi-facebook</v-icon>
				</a>
        <a :href="'https://twitter.com/share?text=Lava+for+Good+-+' + encodeURIComponent(episode.title) + '&url=https%3A%2F%2Flavaforgood.com%2Fpodcast%2F' + episode.slug"  target="_blank">
          <v-icon large class="ma-2">mdi-twitter</v-icon>
				</a>
        <a :href="'mailto:?subject=Lava for Good&body=' + episode.title + '%0D%0A%0D%0Ahttps%3A%2F%2Flavaforgood.com%2Fpodcast%2F' + episode.slug" target="_blank">
          <v-icon large class="ma-2">mdi-email</v-icon>
				</a>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios'
export default {
  name: 'Episode',
  components: {
  },
  data: () => ({
    tab: null,
    episode: {
      special_content: false,
      transcript: false,
    },
  }),
  metaInfo () {
    return {
      title: this.episode.title,
      meta: [
        { name: 'description', content: this.episode.excerpt },
        { vmid: 'og:title', property: 'og:title', content: this.episode.title },
        { vmid: 'og:description', property: 'og:description', content: this.episode.excerpt},
        { vmid: 'og:image', property: 'og:image', content: 'https://lavaforgood.com' + this.getImageSrc(this.episode)},
        { vmid: 'og:image:secure_url', property: 'og:image:secure_url', content: 'https://lavaforgood.com' + this.getImageSrc(this.episode)},
        { vmid: 'og:image:width', property: 'og:image:width', content: '1167'},
        { vmid: 'og:image:height', property: 'og:image:height', content: '584'},
        { vmid: 'og:url', property: 'og:url', content: 'https://lavaforgood.com/podcast/' + this.episode.slug},
        { vmid: 'twitter:title', name: 'twitter:title', content: this.episode.title },
        { vmid: 'twitter:description', name: 'twitter:description', content: this.episode.excerpt},
        { vmid: 'twitter:image:src', name: 'twitter:image:src', content: 'https://lavaforgood.com' + this.getImageSrc(this.episode)},
        // Google / Schema.org markup:
        { vmid: 'nameprop', itemprop: 'name', content: this.episode.title},
        { vmid: 'descprop', itemprop: 'description', content: this.episode.excerpt},
        { vmid: 'imageprop', itemprop: 'image', content: 'https://lavaforgood.com' + this.getImageSrc(this.episode)},
      ]
    }
  },
  computed: {
  },
  methods: {
    plus: function(title) {
      if(title) {
        return title.replace("#",'%23').replace('|', '').replace(/ /g, '+')
      } else {
        return ''
      }
    },
    getImageSrc: function(episode) {
      //if (episode.series_slug == 'bone-valley') {
      //  return '/episodes/bone-valley/E1.jpg'
      //} else
      if (episode.series_slug == 'junk-science') {
        return '/series/junk-science.jpg'
      //} else if (episode.series_slug == 'false-confessions') {
      //  return '/episodes/with-jason-flom/' + episode.episode_identifier + '.jpg'
      } else {
        return '/episodes/' + episode.series_slug + '/' + episode.episode_identifier + '.jpg'
      }
    },
    getGradientTop: function(episode) {
     if (episode.series_slug == 'bone-valley') {
       return 0.6
     } else if(episode.series_slug == 'war-on-drugs') {
       return 0.2
     } else {
       return 0.3
     }
    },
    getGradientBottom: function(episode) {
      if (episode.series_slug == 'bone-valley') {
        return 0.4
      } else if(episode.series_slug == 'war-on-drugs') {
        return 0.7
      } else {
        return 0.8
      }
    },
  },
  mounted () {
    axios
      .get('/api/v1/episode/' + this.$route.params.slug)
      .then((response) => {
        this.episode = response.data.episode
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
</script>
<style lang="sass">
div.episode-desc
  text-align: left
  margin: 0 1em
  font-size: 1.1rem
  color: #dcdcdc

.episode-desc img.headshot
  float: left
  height: 240px
  padding: 0.4em 2.4em 1em 0
a
  text-decoration: none

.speaker
    margin-right: 0.7em
    xcolor: #50596b
    color: #90a4ae

.show-notes
  margin: 2em 0

.show-notes h3
  color: #878787
  margin-top: 1rem

.video-container
  margin-bottom: 2rem


</style>
